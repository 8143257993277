import ReactDOM from "react-dom/client";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import { Provider } from "react-redux";
import store from "./store/store";
import "./styles/style.css";
import CONSTANTS from "./CONSTANTS.json";
import {Helmet} from "react-helmet";

const path = window.location.pathname
const split = path.split("/")

//homepage
let title = 'Kurzy měn - kurzovní lístek ČNB | Kurzymen.cz'
let description = 'Aktuální kurzy měn České národní banky a přehled jejich historického vývoje..'

//kurz
if(split[1] !== undefined && split[1] === 'kurz') {
  // @ts-ignore
  const inflection = CONSTANTS['INFLECTIONS'][split[2]]
  // @ts-ignore
  const shortcode = CONSTANTS['SHORTCODES'][split[2]]

  title = `Kurz ${typeof inflection !== "undefined" ? inflection: ''}  (${shortcode}) | Kurzy měn`
  description = `Aktuální kurz ${typeof inflection !== "undefined" ? inflection: ''} (${shortcode}) a graf jeho vývoje.`
}

document.title = title

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>
    <Provider store={store}>
      <App />
    </Provider>
  </>
);
