import { configureStore } from "@reduxjs/toolkit";
import currencySlice from "./currencySlice";
import historicalCurrencySlice from "./historicalCurrencySlice";
import keySlice from "./keySlice";
import loadingSlice from "./loadingSlice";
import selectedDateSlice from "./selectedDateSlice";
import slicedCurrencySlice from "./slicedCurrencySlice";
import tableLoadingSlice from "./tableLoadingSlice";

const store = configureStore({
  reducer: {
    selectedDate: selectedDateSlice.reducer,
    slicedCurrency: slicedCurrencySlice.reducer,
    loading: loadingSlice.reducer,
    key: keySlice.reducer,
    currencyData: currencySlice.reducer,
    historicalCurrencyData: historicalCurrencySlice.reducer,
    tableLoading: tableLoadingSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
