import { createSlice } from "@reduxjs/toolkit";

const initialState: Date = new Date();

const selectedDateSlice = createSlice({
  name: "selectedDate",
  initialState,
  reducers: {
    setDate(action, { payload }): Date {
      return payload as Date;
    },
  },
});

export default selectedDateSlice;
export const selectedDateSliceActions = selectedDateSlice.actions;
