export type CountryNames =
  | "Austrálie"
  | "Bulharsko"
  | "Dánsko"
  | "EMU"
  | "Hongkong"
  | "Island"
  | "Japonsko"
  | "Jihoafrická rep."
  | "Jižní Korea"
  | "Kanada"
  | "MMF"
  | "Maďarsko"
  | "Norsko"
  | "Nový Zéland"
  | "Polsko"
  | "Singapur"
  | "Švédsko"
  | "Švýcarsko"
  | "USA"
  | "Velká Británie"
  | "Čína"
  | "Filipíny"
  | "Chorvatsko"
  | "Indonésie"
  | "Malajsie"
  | "Rumunsko"
  | "Thajsko"
  | "Turecko";

export type HistoricalCurrencyData = {
  datum: number;
  kodmeny: string;
  kurz: string;
  mena: string;
  mnozstvi: number;
  zeme: string;
};

export type HistoricalExchangeRates = {
  kurz: string;
  datum: string;
};

export type CurrencyData = {
  datum: number;
  datum_before: number;
  kodmeny: string;
  kurz: string;
  kurz_before: string;
  mena: string;
  mnozstvi: string;
  zeme: string;
  historicalExchangeRates: HistoricalExchangeRates[];
};

export type JWTInfo = {
  aud: string;
  exp: number;
  iat: number;
  iss: string;
  nbf: number;
};

export enum PriceChange {
  positive = "positive",
  negative = "negative",
  neutral = "neutral",
}
