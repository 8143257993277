import "animate.css";
import { lazy, Suspense } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import LoadingComp from "./components/LoadingComp";
import "./types";

function App() {
  const ErrorPage = lazy(() => import("./pages/error/ErrorWrapper"));
  const HomePage = lazy(() => import("./pages/Home"));
  const CurrencyPage = lazy(
    () => import("./pages/singleCurrency/CurrencyLogic")
  );
  return (
    <>
      <div className="App">
        <BrowserRouter>
          <div className="position-relative">
            <Suspense fallback={<LoadingComp />}>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route
                  path="/error/*"
                  element={
                    <Suspense fallback={<LoadingComp />}>
                      <ErrorPage />
                    </Suspense>
                  }
                />

                <Route
                  path="/kurz/:id"
                  element={
                    <Suspense fallback={<LoadingComp />}>
                      <CurrencyPage />
                    </Suspense>
                  }
                />
                <Route
                  path="*"
                  element={
                    <Suspense fallback={<LoadingComp />}>
                      <ErrorPage />
                    </Suspense>
                  }
                />
                {/*<Route path="*" element={<Navigate to="/error/404" />} />*/}
              </Routes>
            </Suspense>
          </div>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
