import { createSlice } from "@reduxjs/toolkit";

const initialState: string = "";

const keySlice = createSlice({
    name: 'loading',
    initialState,
    reducers: {
        setKey(state, {payload}) {
           return payload;
        }
    }
}) 

export default keySlice;
export const keySliceActions = keySlice.actions;