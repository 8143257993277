import { createSlice } from "@reduxjs/toolkit";

const initialState: string[][] = [[]];

const slicedCurrencySlice = createSlice({
  name: "currencyData",
  initialState,
  reducers: {
    setSlicedCurrencyData: (state, { payload }) => {
      return payload;
    },
  },
});

export default slicedCurrencySlice;
export const slicedCurrencySliceActions = slicedCurrencySlice.actions;