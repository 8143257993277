import { useEffect, useRef } from "react";
import { useAppSelector } from "../hooks/useAppDispatch";
import { useAppDispatch } from "../hooks/useAppSelector";
import { loadingSliceActions } from "../store/loadingSlice";

function LoadingComp() {
  const isLoading = useAppSelector((state) => state.loading);
  const isTableLoading = useAppSelector((state) => state.tableLoading);
  const loadingRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      !loadingRef ||
      !loadingRef.current ||
      !window.location.pathname.includes("kurz")
    ) {
      dispatch(loadingSliceActions.setLoadingOff());
      return;
    }
    loadingRef.current?.classList.add("fade-out-animation");

    const timeout = setTimeout(() => {
      loadingRef.current?.classList.remove("fade-out-animation");
      dispatch(loadingSliceActions.setLoadingOff());
    }, 1500);
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    () => {
      loadingRef.current?.classList.remove("fade-out-animation");
      dispatch(loadingSliceActions.setLoadingOff());
      clearTimeout(timeout);
    };
  }, [window.location.pathname, loadingRef.current]);

  return (
    <>
      {isLoading && !isTableLoading && (
        <div ref={loadingRef} className="loader-container">
          <div className="loader-ripple">
            <div></div>
            <div></div>
          </div>
        </div>
      )}
    </>
  );
}

export default LoadingComp;
