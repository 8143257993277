import { createSlice } from "@reduxjs/toolkit";

const initialState: boolean = false;

const tableLoadingSlice = createSlice({
  name: "tableLoading",
  initialState,
  reducers: {
    setLoadingOn(): boolean {
      return true;
    },
    setLoadingOff(): boolean {
      return false;
    },
  },
});

export default tableLoadingSlice;
export const tableLoadingSliceActions = tableLoadingSlice.actions;
