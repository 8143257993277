import { createSlice } from "@reduxjs/toolkit";
import { CurrencyData, HistoricalCurrencyData } from "../types";

const initialState: HistoricalCurrencyData[] = [];

const historicalCurrencySlice = createSlice({
  name: "historicalCurrencyData",
  initialState,
  reducers: {
    setCurrencyData: (state, { payload }) => {
      return payload;
    },
  },
});

export default historicalCurrencySlice;
export const historicalCurrencySliceActions = historicalCurrencySlice.actions;
