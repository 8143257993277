import { createSlice } from "@reduxjs/toolkit";
import { CurrencyData } from "../types";

const initialState: CurrencyData[] = [];

const currencySlice = createSlice({
  name: "currencyData",
  initialState,
  reducers: {
    setCurrencyData: (state, { payload }) => {
      return payload;
    },
  },
});

export default currencySlice;
export const CurrencySliceActions = currencySlice.actions;
