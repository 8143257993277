import { createSlice } from "@reduxjs/toolkit";

const initialState: boolean = true;

const loadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    setLoadingOn(): boolean {
      return true;
    },
    setLoadingOff(): boolean {
      return false;
    },
  },
});

export default loadingSlice;
export const loadingSliceActions = loadingSlice.actions;
